<template>
  <v-container class="user-setting-tabs">
    <user-settings-header
      :user="getDriver"
      @openAvatarDialog="openAvatarDialog"
    />
    <v-tabs
      class="setting-tabs"
      vertical
      align-left
      hide-slider
      background-color="setings_tabs"
    >
      <v-tab>
        <v-icon
          left
        >
          mdi-account
        </v-icon>
        <p class="mb-0 mt-1">
          {{ $t('components.lists.drivers.tab_title') }}
        </p>
      </v-tab>
      <v-tab>
        <v-icon
          left
        >
          mdi-beach
        </v-icon>
        <p class="mb-0 mt-1">
          {{ $t('components.lists.vacations.tab_title') }}
        </p>
      </v-tab>
      <v-tab-item>
        <user-tab
          :user="getDriver"
        />
      </v-tab-item>
      <v-tab-item>
        <vacation-tab
          :user="getDriver"
        />
      </v-tab-item>
    </v-tabs>
    <avatar-dialog
      v-model="avatarDialog"
      :has-photo="hasPhoto"
      @updateAvatar="updateAvatar"
      @removeAvatar="removeAvatar"
      @closeModal="closeAvatarDialog"
    />
  </v-container>
</template>
<script>
import VacationTab from '@/components/tabs/settings/users/VacationTab.vue';
import UserTab from '@/components/tabs/settings/users/UserTab.vue';
import UserSettingsHeader from '@/components/headers/UserSettingsHeader.vue';
import AvatarDialog from '@/components/dialogs/users/AvatarDialog.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserSettingTabs',
  components: {
    VacationTab,
    UserTab,
    UserSettingsHeader,
    AvatarDialog,
  },
  data() {
    return {
      avatarDialog: false,
      hasPhoto: false,
      usarId: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getDriver',
      'getRegisterSuccess',
    ]),
  },
  watch: {
    getRegisterSuccess(value) {
      if (value) {
        this.avatarDialog = false;
      }
    },
  },
  mounted() {
    this.userId = this.$route.params.userId;
  },
  methods: {
    ...mapActions([
      'updateDriver',
      'initLoading',
      'removeLoading',
      'removeUserAvatar',
    ]),
    openAvatarDialog(hasPhoto) {
      this.avatarDialog = true;
      this.hasPhoto = hasPhoto;
    },
    closeAvatarDialog() {
      this.avatarDialog = false;
    },
    updateAvatar(avatarBase64) {
      this.initLoading();
      this.updateDriver({
        id: this.userId,
        avatar: avatarBase64,
      });
    },
    removeAvatar() {
      this.initLoading();
      this.removeUserAvatar(this.userId);
    },
  },
};
</script>

<style lang="scss">
.user-setting-tabs {
  .user-settings-header {
    position: absolute;
  }

  .theme--light.v-tabs > .v-tabs-bar {
    margin-top: 130px;
    height: 100%;
    width: 230px;
  }

  .setting-tabs {
    .v-tab {
      justify-content: left;
      text-transform: none;
      font-size: 14px;
      font-family: 'Acumin-Pro-Regular';
    }

    .theme--light.v-tabs-items {
      background-color: $color_background;
    }

    .v-tabs--vertical > .v-tabs-bar .v-tab {
      height: 56px;
      width: 262px;
    }

    .v-tabs--vertical > .v-tabs-bar .v-tabs-slider {
      color:$color_primary;
    }

    .v-tab--active {
      background-color: $color_secondary_dark;
      border-radius: 4px;
    }
  }
}
</style>
