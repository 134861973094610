<template>
  <div>
    <vacations-header
      :show-button="user.is_active"
      @openModal="openModal(false, vacation, false)"
    />

    <vacations-list
      :vacations="getVacations"
      @editVacation="editVacationSetup"
      @deleteVacation="deleteVacationSetup"
    />

    <vacations-dialog
      v-model="dialog"
      :edit="edit"
      :vacation="vacation"
      :reset-form="resetForm"
      :should-delete="shouldDelete"
      @createVacation="newVacation"
      @updateVacation="editVacation"
      @deleteVacation="doDeleteVacation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VacationsList from '@/components/lists/users/VacationsList.vue';
import VacationsHeader from '@/components/headers/users/VacationsHeader.vue';
import VacationsDialog from '@/components/dialogs/users/VacationsDialog.vue';

export default {
  name: 'VacationTab',
  components: {
    VacationsList,
    VacationsHeader,
    VacationsDialog,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      edit: false,
      shouldDelete: false,
      dialog: false,
      resetForm: false,
      vacation: {
        id: '',
        date_init: '',
        date_end: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVacations',
      'getVacationRegisterSuccess',
    ]),
  },
  watch: {
    getVacationRegisterSuccess(value) {
      if (value) {
        this.closeModal();
        this.fetchVacations({ userId: this.$route.params.userId });
        this.resetForm = true;
      }
    },
  },
  mounted() {
    this.fetchVacations({ userId: this.$route.params.userId });
  },
  methods: {
    ...mapActions([
      'fetchVacations',
      'deleteVacation',
      'updateVacation',
      'createVacation',
    ]),
    doDeleteVacation(params) {
      this.deleteVacation({
        id: params.vacationId,
        userId: this.$route.params.userId,
        manager_password: params.managerPassword,
      });
    },
    fetchVacationList() {
      this.fetchVacations({ userId: this.$route.params.userId });
    },
    openModal(isEdit, vacation, isDelete) {
      this.edit = isEdit;
      this.shouldDelete = isDelete;

      if (!isDelete) {
        this.setVacation(isEdit, vacation);
      }

      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    newVacation(payload) {
      this.createVacation(payload);
    },
    editVacation(payload) {
      this.updateVacation(payload);
    },
    editVacationSetup(vacation) {
      this.openModal(true, vacation, false);
    },
    deleteVacationSetup(vacation) {
      this.vacation = vacation;
      this.openModal(true, vacation, true);
    },
    setVacation(isEdit, vacation) {
      if (isEdit) {
        this.vacation = vacation;
      } else {
        this.vacation = {
          id: '',
          date_init: '',
          date_end: '',
        };
      }
    },
  },
};
</script>
