<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="date_init"
          v-mask="'##/##/####'"
          outlined
          dense
          required
          :label="$t('components.lists.vacations.register.form.date_init')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />

        <v-btn
          class="button"
          color="accent"
          @click="submitForm"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="date_end"
          v-mask="'##/##/####'"
          outlined
          dense
          required
          :label="$t('components.lists.vacations.register.form.date_end')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'VacationForm',
  mixins: [formats],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    vacation: {
      type: Object,
      default: () => {},
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: this.vacation.id,
      date_init: this.vacation.date_init,
      date_end: this.vacation.date_end,
    };
  },
  computed: {
    buttonText() {
      if (this.edit) {
        return this.$t('components.lists.vacations.edit.title');
      }

      return this.$t('components.lists.vacations.register.title');
    },
  },
  watch: {
    resetForm(value) {
      if (value) {
        this.clearForm();
      }
    },
    vacation(value) {
      if (value) {
        this.setDataValues(value);
      }
    },
  },
  mounted() {
    this.setDataValues(this.vacation);
  },
  methods: {
    submitForm() {
      if (this.edit) {
        return this.updateVacation();
      }

      return this.createVacation();
    },

    createVacation() {
      const payload = this.assertPayload();

      this.$emit('createVacation', payload);
    },

    updateVacation() {
      const payload = this.assertPayload();

      this.$emit('updateVacation', payload);
    },

    assertPayload() {
      const payload = {
        userId: this.$route.params.userId,
        id: this.id,
        date_init: this.date_init,
        date_end: this.date_end,
      };

      return payload;
    },

    clearForm() {
      this.id = '';
      this.date_init = '';
      this.date_end = '';
    },

    setDataValues(vacation) {
      this.id = vacation.id;
      this.date_init = this.formatDateISO(vacation.date_init, 'dd/MM/yyyy');
      this.date_end = this.formatDateISO(vacation.date_end, 'dd/MM/yyyy');
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
