<template>
  <v-container class="header">
    <div
      v-if="showButtons"
      class="text-right"
    >
      <v-btn
        outlined
        color="accent"
        class="button pa-5 ml-3"
        @click="deactivateUser"
      >
        {{ $t('components.lists.drivers.deactivate.button') }}
      </v-btn>
      <v-btn
        color="accent"
        class="button pa-5 ml-3"
        @click="editUser"
      >
        {{ $t('components.lists.drivers.edit.title') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'UserHeader',
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    deactivateUser() {
      this.$emit('deactivateUser');
    },
    editUser() {
      this.$emit('editUser');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .button {
    font-family: 'Fira-Sans-Bold';
    letter-spacing: 0;
    height: 42px;
    width: 190px;
  }
}

@media screen and (max-width: 1200px) {
  .header {
    .button {
      width: 140px;
      height: 20px;
      font-size: 12px;
    }
  }
}
</style>
