<template>
  <div
    class="user-settings-header"
  >
    <div class="user">
      <div class="user-avatar">
        <v-img
          :title="$t('components.dialogs.avatar.title')"
          class="rounded-lg avatar"
          :src="avatar"
          @click="openAvatarDialog"
        />
      </div>
      <div class="user-information">
        <span class="name">{{ user.name }}</span>
        <br>
        <span class="document"> {{ subtitleText }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import placeholder from '@/assets/avatar_placeholder.png';

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      placeholder,
    };
  },
  computed: {
    subtitleText() {
      if (this.user.is_active) {
        return this.$i18n.t('components.header.users.settings.subtitle');
      }
      return this.user.cpf;
    },
    avatar() {
      if (this.user.avatar && this.user.avatar.url) {
        return this.user.avatar.url;
      }
      return this.placeholder;
    },
  },
  methods: {
    openAvatarDialog() {
      this.$emit('openAvatarDialog', !!this.user.avatar.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-settings-header {
  z-index: 1;
  padding: 12px 0;

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;

    .user-avatar {
      margin-right: 12px;

      .avatar {
        height: 80px;
        width: 80px;
        cursor: pointer;
      }
    }

    .user-information {
      .name {
        font-size: 20px;
        font-family: 'Acumin-Pro-Bold';
      }

    .document {
        font-size: 14px;
        font-family: 'Acumin-Pro-Regular';
        color: $color_subtitle;
      }
    }
  }
}
</style>
