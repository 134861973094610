<template>
  <v-container
    v-if="showButton"
    class="header"
  >
    <div class="text-right">
      <v-btn
        color="accent"
        class="button pa-5 ml-3"
        @click="openDialog"
      >
        {{ $t('components.lists.buttons.register_vacation') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'VacationsHeader',
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openDialog() {
      this.$emit('openModal');
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/headers/drivers.scss" scoped />
