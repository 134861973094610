<template>
  <div class="fill-height">
    <v-simple-table
      v-if="vacationsPresent"
      class="vacations-list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-center"
              width="100px"
            >
              {{ $t('components.lists.vacations.id') }}
            </th>

            <th class="text-center">
              {{ $t('components.lists.vacations.date_init') }}
            </th>

            <th class="text-center">
              {{ $t('components.lists.vacations.date_end') }}
            </th>

            <th />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="vacation in vacations"
            :key="vacation.id"
            class="list-row"
          >
            <td class="text-center">
              {{ vacation.id }}
            </td>

            <td class="text-center">
              {{ formatDateISO(vacation.date_init) }}
            </td>

            <td class="text-center">
              {{ formatDateISO(vacation.date_end) }}
            </td>

            <td
              class="text-center buttons"
              width="100px"
            >
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon mr-3"
                      color="accent"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteVacation(vacation)"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                  <span>{{ $t('components.lists.vacations.tooltips.delete') }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon ml-2"
                      color="accent"
                      v-bind="attrs"
                      v-on="on"
                      @click="editVacation(vacation)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t('components.lists.vacations.tooltips.edit') }}</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <placeholder
      v-else
      :message="$t('components.lists.vacations.empty')"
    />
  </div>
</template>

<script>
import Placeholder from '@/components/lists/Placeholder.vue';
import formats from '@/mixins/formats';

export default {
  name: 'VacationsList',
  components: {
    Placeholder,
  },
  mixins: [formats],
  props: {
    vacations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    vacationsPresent() {
      return this.vacations.length !== 0;
    },
  },
  methods: {
    deleteVacation(vacation) {
      this.$emit('deleteVacation', vacation);
    },
    editVacation(vacation) {
      this.$emit('editVacation', vacation);
    },
  },
};
</script>
