<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="avatarDialog"
      max-width="400px"
      :change="closeModal"
      persistent
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ $t('components.dialogs.avatar.title') }}</span>
        </v-card-title>

        <v-divider />

        <label for="upload">
          <v-card-text class="upload-avatar">
            {{ $t('components.dialogs.avatar.buttons.upload') }}
          </v-card-text>
        </label>

        <v-divider />

        <v-card-text
          v-if="hasPhoto"
          class="remove-avatar"
          @click="removeAvatar"
        >
          {{ $t('components.dialogs.avatar.buttons.remove') }}
        </v-card-text>

        <v-divider />

        <v-card-text
          class="close-dialog"
          @click="closeModal"
        >
          {{ $t('components.dialogs.avatar.buttons.cancel') }}
        </v-card-text>
        <input
          id="upload"
          ref="avatar"
          type="file"
          accept="image/png,image/jpg,image/jpeg"
          hidden
          @change="updateAvatar"
        >
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'AvatarDialog',
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    hasPhoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarDialog: false,
      avatarBase64: null,
    };
  },
  watch: {
    dialog(value) {
      this.avatarDialog = value;
    },
    avatarBase64(value) {
      if (value) {
        this.$emit('closeModal');
        this.$emit('updateAvatar', value);
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    imagePicked(pickedImage) {
      const fileReader = new FileReader();

      fileReader.addEventListener('load', (e) => {
        this.avatarBase64 = e.target.result;
      });

      fileReader.readAsDataURL(pickedImage);
    },
    updateAvatar(event) {
      this.imagePicked(event.target.files[0]);
    },
    removeAvatar() {
      this.$refs.avatar.value = null;
      this.avatarBase64 = null;
      this.$emit('closeModal');
      this.$emit('removeAvatar');
    },
  },
};
</script>

<style lang="scss" scoped>

.dialog-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-dialog > .v-card > .v-card__text {
  padding: 16px 24px;
  cursor: pointer;
  text-align: center;
  font-family: 'Acumin-Pro-Regular';
  font-size: 14px;
}

.upload-avatar {
  color: $color_primary;
  text-align: center;
  cursor: pointer;
  font-family: 'Acumin-Pro-Regular';
  font-size: 14px;
}

.remove-avatar {
  color: red !important;
}
</style>
