<template>
  <v-container :class="userDetailsClass">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.name') }}
          </p>
          <p class="value">
            {{ user.name }}
          </p>
        </div>
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.cpf') }}
          </p>
          <p class="value">
            {{ user.cpf }}
          </p>
        </div>
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.phone') }}
          </p>
          <p class="value">
            {{ user.phone }}
          </p>
        </div>
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.email') }}
          </p>
          <p class="value">
            {{ user.email }}
          </p>
        </div>
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.admission_date') }}
          </p>
          <p class="value">
            {{ formatDateISO(user.admission_date) }}
          </p>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="gutter-left"
      >
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.kind') }}
          </p>
          <p class="value">
            {{ user.driver_profile_name }}
          </p>
        </div>
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.unit') }}
          </p>
          <p class="value">
            {{ user.business_unit_name }}
          </p>
        </div>
        <div class="user-detail">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.branch') }}
          </p>
          <p class="value">
            {{ user.branch_name }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'UserDetails',
  mixins: [formats],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userDetailsClass() {
      if (this.user.is_active) {
        return 'user-details';
      }
      return 'deactivated-user-details';
    },
  },
};
</script>

<style lang="scss" scoped>

.user-details {
  padding: 0 78px 32px;
  margin-top: 54px;

  .user-detail {
    font-family: 'Acumin-Pro-Regular';
    font-size: 16px;

    .header {
      color: $color_primary;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .value {
      margin-bottom: 0;
    }
  }
}

.deactivated-user-details {
  @extend .user-details;
  margin-top: 96px;
}
</style>
