<template>
  <div>
    <user-setting-tabs v-if="hasDriver" />
    <div
      v-else
      class="text-center accent--text font-weight-black"
    >
      {{ $t('views.user_settings.not_found') }}
    </div>
  </div>
</template>

<script>
import UserSettingTabs from '@/components/tabs/UserSettingTabs.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Setting',
  components: {
    UserSettingTabs,
  },
  computed: {
    ...mapGetters(['getDriver']),

    hasDriver() {
      return this.getDriver.id != null;
    },
  },
  mounted() {
    this.initLoading();
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.register_driver'),
        disabled: false,
        exact: true,
        to: {
          name: 'userList',
        },
      },
      {
        text: this.$i18n.t('breadcrumbs.user_settings'),
        disabled: true,
      },
    ]);

    this.fetchStoreDriver({ id: this.$route.params.userId });
  },
  methods: {
    ...mapActions([
      'initLoading',
      'fetchStoreDriver',
      'initCurrentBreadcrumbs',
    ]),
  },
};
</script>

<style lang="scss">
.cancel-button {
  border: 1px solid $color_primary;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
}

.spacing {
  margin: 8px;
}

.v-btn.v-size--default {
  color: $color_primary;
}
</style>
