<template>
  <div>
    <user-header
      :show-buttons="user.is_active"
      @deactivateUser="openModal(false, true)"
      @editUser="openModal(true, false)"
    />
    <driver-dialog
      v-model="dialogDriver"
      :edit="edit"
      :user="user"
      :states="getStates"
      :deactivate="shouldDeactivate"
      :profiles="getDriversProfiles"
      :business-units="getBusinessUnits"
      :branches="getBranches"
      @updateUser="updateUser"
      @deactivateDriver="deactivateDriver"
      @fetchBranchesByBusinessUnit="fetchBranches"
    />
    <user-details
      :user="user"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserDetails from '@/components/details/users/UserDetails.vue';
import DriverDialog from '@/components/dialogs/DriverDialog.vue';
import UserHeader from '@/components/headers/users/UserHeader.vue';

export default {
  name: 'UserTab',
  components: {
    UserHeader,
    DriverDialog,
    UserDetails,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      edit: false,
      shouldDeactivate: false,
      dialogDriver: false,
    };
  },
  computed: {
    ...mapGetters([
      'getStates',
      'getDriversProfiles',
      'getRegisterSuccess',
      'getBusinessUnits',
      'getBranches',
    ]),
  },
  watch: {
    getRegisterSuccess(value) {
      if (value) {
        this.fetchStoreDriver({ id: this.user.id });
        this.closeModal();
      }
    },
  },
  mounted() {
    if (this.getStates.length === 0) {
      this.initLoading();
      this.fetchStates();
      this.fetchDriverProfiles();
      this.fetchBusinessUnits();
      this.removeLoading();
    }
  },
  methods: {
    ...mapActions([
      'fetchStoreDriver',
      'fetchStates',
      'updateDriver',
      'deactivateUser',
      'fetchDriverProfiles',
      'fetchBusinessUnits',
      'fetchBranches',
      'initLoading',
      'removeLoading',
    ]),

    openModal(isEdit, isDeactivate) {
      this.edit = isEdit;
      this.shouldDeactivate = isDeactivate;
      this.dialogDriver = true;
    },

    closeModal() {
      this.dialogDriver = false;
    },

    deactivateDriver(managerPassword, userId) {
      this.deactivateUser({ id: userId, manager_password: managerPassword });
    },

    updateUser(payload) {
      this.updateDriver(payload);
    },
  },
};
</script>
