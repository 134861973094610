<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogInfos.title }}</span>
        </v-card-title>
        <v-card-text
          class="dialog-subtitle"
        >
          {{ dialogInfos.subtitle }}
        </v-card-text>
        <v-card-text
          v-if="shouldDelete"
          class="dialog-details"
        >
          <vacation-details
            :vacation="vacation"
            :password-clean="dialogOpen"
            @deleteVacation="deleteVacation"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="dialog-form"
        >
          <vacation-form
            :edit="edit"
            :vacation="vacation"
            :reset-form="resetForm"
            @createVacation="createVacation"
            @updateVacation="updateVacation"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import VacationForm from '@/components/forms/register/users/VacationForm.vue';
import VacationDetails from '@/components/details/users/VacationDetails.vue';

export default {
  name: 'VacationsDialog',
  components: {
    VacationForm,
    VacationDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    shouldDelete: {
      type: Boolean,
      default: false,
    },
    vacation: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  computed: {
    shouldCleanForm() {
      return this.resetForm;
    },

    dialogInfos() {
      if (this.delete) {
        return {
          title: this.$t('components.lists.vacations.delete.title'),
          subtitle: this.$t('components.lists.vacations.delete.subtitle'),
        };
      }

      if (this.edit) {
        return {
          title: this.$t('components.lists.vacations.edit.title'),
          subtitle: this.$t('components.lists.vacations.edit.subtitle'),
        };
      }

      return {
        title: this.$t('components.lists.vacations.register.title'),
        subtitle: this.$t('components.lists.vacations.register.subtitle'),
      };
    },
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    updateVacation(payload) {
      return this.$emit('updateVacation', payload);
    },

    createVacation(payload) {
      return this.$emit('createVacation', payload);
    },

    deleteVacation(managerPassword, vacationId) {
      this.$emit('deleteVacation', { managerPassword, vacationId });
    },

    closeModal() {
      this.$emit('change', this.dialogOpen);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
